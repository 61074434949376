import axios from 'axios';
import {baseUrl} from '../config/environment.js';

let baseURL = baseUrl;
const service = axios.create({
    baseURL: baseURL, timeout: 5000
});

service.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
});

service.interceptors.response.use(response => {
    // console.log(`interceptor response:`, response)
    return response.data;
}, error => {
    // console.error(`interceptor error:`, error)
    return Promise.reject(error.response.data);
});

export default service;
