<template>
  <div>
    <div class="control">
      <mu-button @click="getLoginQr" color="primary">1.预获取二维码</mu-button>
      <mu-button @click="loginCreate" color="primary">2.创建二维码</mu-button>
      <mu-button @click="checkLoginQr" color="primary">3.检查二维码</mu-button>
      <mu-button @click="checkLoginStatus" color="primary">4.检查登录状态</mu-button>
      <mu-button @click="setCookieToServer" color="primary">5.提交登录信息</mu-button>
    </div>

    <div class="" style="max-height: 300px; overflow: auto;">
      <div>qrKey: {{ qrKey }}</div>
      <div>qrImg: {{ qrImg }}</div>
      <div>login status: {{ JSON.stringify(loginStatus, null, 2) }}</div>
      <div>cookie: {{ cookie }}</div>
    </div>

    <div>
      <img :src="qrImg" alt="扫码登录">
    </div>

    <div class="log">
      <!--      <el-input-->
      <!--          id="textarea"-->
      <!--          type="textarea"-->
      <!--          :rows="10"-->
      <!--          placeholder=""-->
      <!--          v-model="log"-->
      <!--          readonly=""-->
      <!--      >-->
      <!--      </el-input>-->

<!--      <mu-container>-->
        <mu-text-field id="textarea" v-model="log" placeholder="默认3行, 最大显示6行" multi-line :rows="3"
                       :rows-max="10" full-width></mu-text-field>
        <br/>
<!--      </mu-container>-->
    </div>
  </div>
</template>

<script>
import {
  neteaseLoginCreate,
  neteaseLoginQr,
  neteaseLoginQrCheck,
  neteaseLoginStatus,
  setCookie
} from "../api/musicoooo.js";
import {
  loginStatus,
} from "../api/nc.js";

export default {
  name: "NCLogin",
  data() {
    return {
      qrKey: null,
      qrImg: null,
      loginStatus: null,
      cookie: null,

      checkQrTaskInterval: null,
      checkStatusInterval: null,

      log: "",
    }
  },

  watch: {
    qrKey(newVal, oldVal) {
      if (newVal === null) {
        console.log(`[watch qr key] qr key 空, 准备获取`)
        this.getLoginQr();
      } else {
        console.log(`[watch qr key] qr key 有值, 准备创建二维码`)
        this.loginCreate();
      }
    },

    cookie(newVal, oldVal) {
      if (newVal === null) {
        return;
      } else {
        console.log(`[watch cookie] 获取到 cookie 请提交给服务端`)
        // setTimeout(() => {
        //   this.setCookieToServer();
        // }, 1000)
      }
    }
  },

  created() {
    const _this = this;

    // fetch login qr key
    this.getLoginQr();

    // check qr task
    this.checkQrTaskInterval = setInterval(() => {
      _this.checkLoginQr();
    }, 1000);
    console.log(`[set cookie] 开启检查 qr key 任务`)

    // check status
    this.checkStatusInterval = setInterval(() => {
      _this.checkLoginStatus();
    }, 1000);
    console.log(`[set cookie] 开启检查登录状态任务`)
  },

  methods: {
    getLoginQr: function () {
      let key = null;
      neteaseLoginQr().then(response => {
        key = response.data.data.uniKey;
        this.qrKey = key;
        console.log(`[login qr] fetch `, response)
        this.toLog(`[login qr] fetch ${key}`)
      });
    },

    checkLoginQr: function () {
      const key = this.qrKey;
      if (key === null) return;

      neteaseLoginQrCheck(key).then(response => {
        const code = response.data.code;
        const data = response.data;
        if (code == 801) {
          console.log(`[check qr] `, response)
          this.toLog(`[check qr] ${data.message}`)
        } else if (code == 802) {
          console.log(`[check qr] 授权中`, response)
          this.toLog(`[check qr] 授权中`)
        } else if (code == 803) {
          this.cookie = response.data.cookie;

          console.log(`[check qr] 授权登陆成功, 获取到 cookie`, response)
          this.toLog(`[check qr] 授权登陆成功`)

          clearInterval(this.checkQrTaskInterval)
          console.log(`[set cookie] 关闭检查 qr key 任务`)
        } else {
          this.qrKey = null;
          console.log(`[check qr] 重置`, response)
          this.toLog(`[check qr] 重置, ${data.message}`)
        }
      }).catch(err => {
        this.qrKey = null;
        console.log(`[check qr] err, 重置`, err)
        this.toLog(`[check qr] err, 重置`)
      })
    },

    loginCreate: function () {
      const k = this.qrKey;
      const i = 1;
      neteaseLoginCreate(k, i).then(response => {
        const data = response.data.data;
        this.qrImg = data.qrimg;
        console.log(`[qr create] created:`, response)
        this.toLog(`[qr create] created: ${data.qrurl}`)
      }).catch(err => {
        console.log(`[qr create] err`, err)
        this.toLog(`[qr create] err`)
      })
    },

    checkLoginStatus: function () {
      const cookie = this.cookie;
      if (cookie == null) return;
      // neteaseLoginStatus(cookie).then(response => {
      //   this.loginStatus = response;
      //   console.log(`[status] `, response)
      //   this.toLog(`[status] ${JSON.stringify(response)}`)
      //
      //   clearInterval(this.checkStatusInterval);
      //   console.log(`[set cookie] 关闭检查登录状态任务`)
      // });

      loginStatus(cookie).then(response => {
        const data = response.data;
        this.loginStatus = data;
        console.log(`[status] `, data)
        this.toLog(`[status] 成功`)

        clearInterval(this.checkStatusInterval);
        console.log(`[set cookie] 关闭检查登录状态任务`)
      });
    },

    setCookieToServer: function () {
      const status = this.loginStatus;
      const cookie = this.cookie;

      if (status === null || cookie === null) {
        return;
      }

      let acc = status.account.id;
      if (!acc) {
        acc = '374558142'
      }

      let coo = cookie;

      setCookie(null, acc, coo).then(response => {
        console.log(`[set cookie] 设置成功`, response);
        this.toLog(`[set cookie] 设置成功`);

        clearInterval(this.checkStatusInterval);
        console.log(`[set cookie] 关闭检查登录状态任务`)
      }).catch(err => {
        console.log(`[set cookie] 设置失败 err`, err)
      })
    },

    toLog: function (data) {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      if (month < 10 || month.length === 1) month = `0${month}`;
      let date = now.getDate();
      if (date < 10 || date.length === 1) date = `0${date}`;
      let hours = now.getHours();
      if (hours < 10 || hours.length === 1) hours = `0${hours}`;
      let minutes = now.getMinutes();
      if (minutes < 10 || minutes.length === 1) minutes = `0${minutes}`;
      let seconds = now.getSeconds();
      if (seconds < 10 || seconds.length === 1) seconds = `0${seconds}`;

      let dateStr = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
      this.log += `${dateStr} - ${data}` + "\n";

      setTimeout(() => {
        const textarea = document.getElementById("textarea");
        if (textarea)
          textarea.scrollTop = textarea.scrollHeight;
      }, 20);
    },
  }
}
</script>

<style scoped lang="scss">

</style>
