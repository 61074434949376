import request from '../utils/request';

let PREFIX = '/m/nc';

if (process.env.NODE_ENV === "production") {
    PREFIX = '' + PREFIX;
}

export function loginQrKey() {
    let url = `${PREFIX}/login/qr/key`
    return request({
        url: url,
        method: 'get'
    })
}

export function loginQrCreate(key) {
    let url = `${PREFIX}/login/qr/create?key=${key}&qrimg=1`
    return request({
        url: url,
        method: 'get'
    })
}

export function loginStatus(cookie) {
    const timestamp = new Date().getTime();

    let url = `${PREFIX}/login/status?timestamp=${timestamp}`
    return request({
        url: url,
        method: 'post',
        header: {
            'Content-Type': 'application/json'
        },
        data: {
            cookie
        }
    })
}
