import request from '../utils/request';

let PREFIX = '/room';

if (process.env.NODE_ENV === "production") {
    PREFIX = '/api' + PREFIX;
}

export function get(roomId) {
    if (roomId === undefined || roomId === null) {
        roomId = '';
    }
    let url = `${PREFIX}/get?id=${roomId}`
    return request({
        url: url,
        method: 'get'
    })
}

export function create(room) {
    let data = {
        title: room.title,
        description: room.description,
        ifPublic: room.ifPublic,
        adminPassword: room.adminPassword,
        accessPassword: room.accessPassword,
        voteSkipRate: room.voteSkipRate,
        playlistId: room.playlistId,
    }
    return request({
        url: PREFIX + '/create',
        method: 'post',
        header: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function page(search) {
    let params = {
        pageIndex: search.pageIndex,
        pageSize: search.pageSize,
        keyword: search.keyword
    }
    return request({
        url: PREFIX + '/page',
        method: 'get',
        params: params
    })
}
