<template>
  <mu-dialog v-if="room.openDialog" v-show="dialogShow" id="search" width="auto" :scrollable="fullscreen"
             :fullscreen="fullscreen"
             :open.sync="room.openDialog"
             class="">
    <div style="display: flex; flex-direction: column;">
      <div style="display: flex">
        <mu-container>
          <mu-row>
            <mu-col span="9" sm="10" md="10" lg="10" xl="10">
              <mu-text-field :value="keyword"
                             @input="updateSearchKeyword"
                             @keydown.enter="search"
                             placeholder="请输入关键字搜索..."
                             color="#009688"
                             class="width-size-100" style="text-align: center"></mu-text-field>
            </mu-col>
            <mu-col span="3" sm="2" md="2" lg="2" xl="2" style="display: flex; align-items: center">
              <mu-button class="search_btn" icon @click="search">
                <mu-icon value="search"></mu-icon>
              </mu-button>
              <mu-button icon color="primary" @click="joinByRoom">
                <mu-icon value="send"></mu-icon>
              </mu-button>
            </mu-col>
          </mu-row>
          <mu-row>
            <mu-data-table id="dialogDataTable" style="background-color: transparent" :selectable="false" :hover="false"
                           :columns="columns" :data="dataList">
              <template slot-scope="scope">
                <td v-if="fullscreen" class="is-center">
                  <a class="search_pick_btn_m" v-if="checkIfCanJoin(scope.row.id)" @click="join(scope.row)">加入</a>
                  <mu-tooltip v-else content="当前房间">
                    <a class="search_pick_btn_disable_m">加入</a>
                  </mu-tooltip>
                </td>
                <td class="is-left">{{ scope.row.title }}</td>
                <td class="is-left">{{ scope.row.id }}</td>
                <mu-tooltip v-if="scope.row.type === 1" content="临时房间24小时内没有人使用会被清理掉">
                  <td class="is-left">
                    {{ valueOfType(scope.row.type) }}
                  </td>
                </mu-tooltip>
                <td v-else class="is-left">
                  {{ valueOfType(scope.row.type) }}
                </td>
                <td class="is-left">{{ scope.row.description }}</td>
                <td v-if="!fullscreen" class="is-center">
                  <a class="search_pick_btn" v-if="checkIfCanJoin(scope.row.id)" @click="join(scope.row)">加入</a>
                  <mu-tooltip v-else content="当前房间">
                    <a class="search_pick_btn_disable">加入</a>
                  </mu-tooltip>
                </td>
              </template>
            </mu-data-table>
          </mu-row>
          <mu-row>
            <mu-flex justify-content="center">
              <mu-pagination :total="page.total"
                             :current.sync="page.current"
                             :page-count="7"
                             :page-size="page.limit"
                             @change="paginationChange"></mu-pagination>
            </mu-flex>
          </mu-row>
        </mu-container>
      </div>
      <div style="display: flex; flex-direction: column;">
        <div v-if="fullscreen" style="margin: 0 auto;">
          <mu-button color="red" icon style="margin: 0 auto;" @click="room.openDialog = false">
            <mu-icon value="cancel"></mu-icon>
          </mu-button>
        </div>
        <div v-if="fullscreen" style="margin: 0 auto;">
          <small style="color: #078c80 !important">若需查看更多信息，请水平滚动表格。</small>
        </div>
      </div>
    </div>
  </mu-dialog>
</template>

<script>

import {get, page} from "../api/room";

export default {
  name: "RoomSelect",
  props: {
    room: {
      openDialog: false,
      switch: false,
      id: '1',
      title: '广场',
      description: ''
    },

  },
  data: () => {
    return {
      fullscreen: true,
      screenWidth: document.documentElement.clientWidth,
      dialogShow: false,

      keyword: null,
      columns: [
        {title: '房间名', name: 'title', width: 100, align: 'left'},
        {title: '房间号', name: 'id', width: 100, align: 'left'},
        {title: '类型', name: 'type', width: 100, align: 'left'},
        {title: '描述', name: 'description', align: 'left'},
        {title: '操作', name: 'op', align: 'center'}
      ],
      fullScreenColumns: [
        {title: '操作', name: 'op', align: 'center'},
        {title: '房间名', name: 'title', width: 100, align: 'left'},
        {title: '房间号', name: 'id', width: 100, align: 'left'},
        {title: '类型', name: 'type', width: 100, align: 'left'},
        {title: '描述', name: 'description', align: 'left'},
      ],
      normalColumns: [
        {title: '房间名', name: 'title', width: 100, align: 'left'},
        {title: '房间号', name: 'id', width: 100, align: 'left'},
        {title: '类型', name: 'type', width: 100, align: 'left'},
        {title: '描述', name: 'description', align: 'left'},
        {title: '操作', name: 'op', align: 'center'}
      ],
      dataList: [],
      page: {
        current: 1,
        limit: 10,
        count: 7,
        total: 0
      }
    }
  },
  methods: {
    updateSearchKeyword: function (value) {
      this.keyword = value;
    },
    search: function () {
      let _this = this;
      let params = {
        keyword: _this.keyword,
        pageIndex: _this.page.current,
        pageSize: _this.page.limit
      };

      page(params).then(response => {
        // console.log("response", response);
        let data = response.data;
        this.dataList = data.records;
        this.page.total = data.total;
      })
    },
    paginationChange: function (page) {
      this.page.current = page;
      this.search()
    },
    join: function (row) {
      this.doJoin(row.id, row.title, row.description);
    },
    doJoin: function (id, title, description) {
      window.localStorage.setItem('ROOM', id);
      window.localStorage.setItem('ROOM_NAME', title);
      this.room.id = id;
      this.room.title = title;
      this.room.description = description;
      this.room.openDialog = false;
      this.room.switch = true;
    },
    checkIfCanJoin: function (id) {
      if (id === undefined || id === null) {
        return false;
      }
      if (this.room.id === undefined || this.room.id === null) {
        return true;
      }
      return String(this.room.id) !== String(id)
    },
    joinByRoom: function () {
      let roomId = this.keyword;
      if (roomId === null) {
        this.$toast.message("请输入房间号");
        return;
      }

      if (!this.checkIfCanJoin(roomId)) {
        this.$toast.message("您已在当前房间");
        return;
      }

      let _this = this;
      get(roomId).then(response => {
        let data = response.data;
        if (data) {
          _this.doJoin(data.id, data.title, data.description);
        } else {
          this.$toast.message(`${response.message}`);
        }
      });
    },

    valueOfType: function (type) {
      if (type === undefined || type === null || type === '') {
        return '';
      }
      if (type === 0) {
        return '长期';
      } else if (type === 1) {
        return '临时';
      }
      return '';
    },

    getScreenWidth: function () {
      return document.documentElement.clientWidth;
    },

    handleResize: function () {
      this.screenWidth = document.documentElement.clientWidth;
      if (this.screenWidth <= 800) {
        this.fullscreen = true;
        this.columns = this.fullScreenColumns;
      } else {
        this.fullscreen = false;
        this.columns = this.normalColumns;
      }
    },

    resetDialogFullscreen: function () {
      this.dialogShow = false;
      if (this.fullscreen) {
        setTimeout(() => {
          let muDialogBody = document.querySelector(".mu-dialog-body");
          if (muDialogBody != null) {
            muDialogBody.classList.add("a");
          }

          let muTable = document.querySelector("#dialogDataTable");
          if (muTable != null) {
            muTable.classList.add("b");
          }

          this.dialogShow = true;
        }, 10);
      } else {
        setTimeout(() => {
          let muDialogBody = document.querySelector(".mu-dialog-body");
          if (muDialogBody != null) {
            muDialogBody.classList.remove("a");
          }

          let muTable = document.querySelector("#dialogDataTable");
          if (muTable != null) {
            muTable.classList.remove("b");
          }

          this.dialogShow = true;
        }, 10);
      }
    }
  },
  created() {
    let _this = this;
    if (this.dataList === null || this.dataList.length === 0) {
      this.search();
    } else {
      // console.log("-----")
    }


    // window.onresize = () => {
    //   return (() => {
    //     _this.screenWidth = document.documentElement.clientWidth;
    //     console.log(_this.screenWidth)
    //     _this.resetDialogFullscreen();
    //   })()
    // };
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.handleResize();
    this.resetDialogFullscreen();
    window.addEventListener('resize', this.handleResize);
  },
  watch: {
    // screenWidth: {
    //   handler(newVal, oldVal) {
    //     this.resetDialogFullscreen();
    //   }
    // },
    // room: {
    //   deep: true,
    //   handler(newVal, oldVal) {
    //     if (newVal.openDialog) {
    //       this.resetDialogFullscreen();
    //     }
    //   }
    // }

    fullscreen: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.resetDialogFullscreen();
        }
      }
    }
  }
}
</script>

<style lang="scss">
.a {
  margin: 0 auto;
  height: 100vh !important;
  max-height: 100vh !important;
}

.b {
  height: 75vh;
}
</style>

<style lang="scss" scoped>
///deep/ .mu-dialog-body {
//  margin: 0 auto;
//  height: 100vh !important;
//  max-height: 100vh !important;
//}


///deep/ .mu-table {
//  height: 75vh;
//}


</style>
