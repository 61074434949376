<template>
  <mu-dialog v-if="params.openDialog" v-show="dialogShow" id="search" width="auto" :scrollable="fullscreen"
             :fullscreen="fullscreen"
             :open.sync="params.openDialog"
             class="">
    <div style="display: flex; flex-direction: column;">
      <div style="display: flex">
        <mu-container>
          <mu-row>
            <mu-col span="11">
              <mu-text-field :value="keyword"
                             @input="updateSearchKeyword"
                             @keydown.enter="search"
                             placeholder="请输入关键字搜索..."
                             color="#009688"
                             class="width-size-100" style="text-align: center"></mu-text-field>
            </mu-col>
            <mu-col span="1">
              <mu-button class="search_btn" icon @click="search">
                <mu-icon value="search"></mu-icon>
              </mu-button>
            </mu-col>
          </mu-row>
          <mu-row>
            <mu-data-table id="dialogDataTable" style="background-color: transparent" :selectable="false" :hover="false"
                           :columns="columns" :data="searchData">
              <template slot-scope="scope">
                <td class="is-left">{{ scope.$index + 1 }}</td>

                <td v-if="fullscreen" class="is-center">
                  <a v-if="showPickButton(scope.row.privilege)" class="search_pick_btn_m"
                     @click="pickMusic(scope.row)">点歌</a>
                  <mu-tooltip v-if="!showPickButton(scope.row.privilege)" content="当前音乐不能点播">
                    <a v-if="" class="search_pick_btn_disable_m">点歌</a>
                  </mu-tooltip>
                </td>

                <td class="is-left">{{ scope.row.name }}
                </td>
                <td class="is-center">{{ scope.row.artist }}</td>
                <td class="is-center">{{ '《' + scope.row.album + '》' }}</td>
                <td class="is-center">{{ formatterTime(scope.row.duration / 1000) }}</td>

                <td v-if="!fullscreen" class="is-center">
                  <a v-if="showPickButton(scope.row.privilege)" class="search_pick_btn"
                     @click="pickMusic(scope.row)">点歌</a>
                  <mu-tooltip v-if="!showPickButton(scope.row.privilege)" content="当前音乐不能点播">
                    <a v-if="" class="search_pick_btn_disable">点歌</a>
                  </mu-tooltip>
                </td>
              </template>
            </mu-data-table>
          </mu-row>
          <mu-row>
            <mu-flex justify-content="center">
              <mu-pagination :total="searchCount" :current.sync="current" :page-count="pageCount" :page-size="limit"
                             @change="paginationChange"></mu-pagination>
            </mu-flex>
          </mu-row>
        </mu-container>
      </div>
      <div style="display: flex; flex-direction: column;">
        <div v-if="fullscreen" style="margin: 0 auto;">
          <mu-button color="red" icon style="margin: 0 auto;" @click="params.openDialog = false">
            <mu-icon value="cancel"></mu-icon>
          </mu-button>
        </div>
        <div v-if="fullscreen" style="margin: 0 auto;">
          <small style="color: #078c80 !important">若需查看更多信息，请水平滚动表格。</small>
        </div>
      </div>
    </div>
  </mu-dialog>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';
import {timeUtils} from "../utils";

export default {
  name: "MusicSearch",
  props: {
    params: {
      openDialog: false
    },
  },
  computed: {
    ...mapGetters({
      searchKeyword: 'getSearchKeyword',
      searchData: 'getSearchData',
      searchCount: 'getSearchCount'
    }),
    ...mapMutations({
      // volume: 'setPlayerVolume'
    }),
  },
  data: () => {
    return {
      fullscreen: true,
      screenWidth: document.documentElement.clientWidth,
      dialogShow: false,

      current: 1,
      limit: 10,
      pageCount: 7,

      keyword: null,
      columns: [
        {title: '序号', name: 'id', width: 80, align: 'left'},
        {title: '歌曲', name: 'name', width: 200, align: 'left'},
        {title: '歌手', name: 'artist', align: 'center'},
        {title: '专辑', name: 'album', align: 'center'},
        {title: '时长', name: 'duration', align: 'center'},
        {title: '操作', name: 'op', align: 'center'}
      ],
      fullScreenColumns: [
        {title: '序号', name: 'id', width: 80, align: 'left'},
        {title: '操作', name: 'op', align: 'center'},
        {title: '歌曲', name: 'name', width: 200, align: 'left'},
        {title: '歌手', name: 'artist', align: 'center'},
        {title: '专辑', name: 'album', align: 'center'},
        {title: '时长', name: 'duration', align: 'center'},
      ],
      normalColumns: [
        {title: '序号', name: 'id', width: 80, align: 'left'},
        {title: '歌曲', name: 'name', width: 200, align: 'left'},
        {title: '歌手', name: 'artist', align: 'center'},
        {title: '专辑', name: 'album', align: 'center'},
        {title: '时长', name: 'duration', align: 'center'},
        {title: '操作', name: 'op', align: 'center'}
      ],
      dataList: [],
      page: {
        current: 1,
        limit: 10,
        count: 7,
        total: 0
      }
    }
  },
  methods: {
    updateSearchKeyword: function (value) {
      this.keyword = value;
    },
    search: function () {
      let stompClient = this.$store.getters.getStompClient;
      stompClient.send('/music/search', {}, JSON.stringify({
        name: this.keyword,
        sendTime: Date.now(),
        pageIndex: this.current,
        pageSize: this.limit
      }));
    },
    paginationChange: function (page) {
      this.page.current = page;
      this.search();
    },
    showPickButton(value) {
      return true;
      /*
        if (Number(value.st) < 0) {
            // 没有资源
            return false;
        } else if (Number(value.fl) === 0) {
            // 可能需要付费
            return false;
        }
        return true
       */
    },
    pickMusic: function (row) {
      let stompClient = this.$store.getters.getStompClient;
      stompClient.send('/music/pick', {}, JSON.stringify({
        id: row.id,
        name: row.name,
        sendTime: Date.now()
      }));
      this.$toast.message(`[${row.id}]${row.name} - 已发送点歌请求`);
    },
    formatterTime: function (value) {
      return timeUtils.secondsToHH_mm_ss(value)
    },

    getScreenWidth: function () {
      return document.documentElement.clientWidth;
    },

    handleResize: function () {
      this.screenWidth = document.documentElement.clientWidth;
      if (this.screenWidth <= 800) {
        this.fullscreen = true;
        this.columns = this.fullScreenColumns;
      } else {
        this.fullscreen = false;
        this.columns = this.normalColumns;
      }
    },

    resetDialogFullscreen: function () {
      this.dialogShow = false;
      if (this.fullscreen) {
        setTimeout(() => {
          let muDialogBody = document.querySelector(".mu-dialog-body");
          if (muDialogBody != null) {
            muDialogBody.classList.add("a");
          }

          let muTable = document.querySelector("#dialogDataTable");
          if (muTable != null) {
            muTable.classList.add("b");
          }

          this.dialogShow = true;
        }, 10);
      } else {
        setTimeout(() => {
          let muDialogBody = document.querySelector(".mu-dialog-body");
          if (muDialogBody != null) {
            muDialogBody.classList.remove("a");
          }

          let muTable = document.querySelector("#dialogDataTable");
          if (muTable != null) {
            muTable.classList.remove("b");
          }

          this.dialogShow = true;
        }, 10);
      }
    }
  },
  created() {
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.handleResize();
    this.resetDialogFullscreen();
    window.addEventListener('resize', this.handleResize);
  },
  watch: {
    fullscreen: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.resetDialogFullscreen();
        }
      }
    }
  }
}
</script>

<style lang="scss">
.a {
  margin: 0 auto;
  height: 100vh !important;
  max-height: 100vh !important;
}

.b {
  height: 75vh;
}
</style>

<style lang="scss" scoped>
///deep/ .mu-dialog-body {
//  margin: 0 auto;
//  height: 100vh !important;
//  max-height: 100vh !important;
//}


///deep/ .mu-table {
//  height: 75vh;
//}


</style>
