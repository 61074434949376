import request from '../utils/request';

let PREFIX = '/musicoooo';

if (process.env.NODE_ENV === "production") {
    PREFIX = '/api' + PREFIX;
}

export function setCookie(channel, account, cookie) {
    if (channel === undefined || channel === null) {
        channel = 'netease';
    }

    const data = {
        acc: account,
        coo: cookie
    }

    let url = `${PREFIX}/cookie/${channel}`
    return request({
        url: url,
        method: 'post',
        header: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function delCookie(channel, account) {
    if (channel === undefined || channel === null) {
        channel = 'netease';
    }

    const data = {
        acc: account
    }

    let url = `${PREFIX}/cookie/${channel}`
    return request({
        url: url,
        method: 'delete',
        header: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function neteaseLoginQr() {
    let url = `${PREFIX}/netease/login/qr`
    return request({
        url: url,
        method: 'get',
    })
}

export function neteaseLoginCreate(key, qrimg) {
    let url = `${PREFIX}/netease/login/create?key=${key}&qrimg=${qrimg}`
    return request({
        url: url,
        method: 'get',
    })
}

export function neteaseLoginQrCheck(key) {
    let url = `${PREFIX}/netease/login/qr/check?key=${key}`
    return request({
        url: url,
        method: 'get',
    })
}

export function neteaseLoginStatus(cookie) {
    let url = `${PREFIX}/netease/login/status`
    const data = {
        coo: cookie
    }
    return request({
        url: url,
        method: 'get',
        header: {
            'Content-Type': 'application/json'
        },
        data: data
    })
}




